<template>
	<div id="recruit">
		<banner :picS="bannerS"></banner>
		<div class="recruitment">
			<titleOne id="recruit_1" en='recruitment' :title="lang.title.text16"></titleOne>
			<div class="widthBox">
				<recruitMboile v-if="mobile"/>
				<recruitBox v-else/>
			</div>
		</div>
	</div>
</template>

<script>
	import titleOne from '@/components/titleOne.vue'
	import banner from '@/components/banner.vue'
	import recruitBox from '@/components/recruit.vue'
	import recruitMboile from '@/components/recruit_mobile.vue'
	export default {
		name: 'recruit',
		components:{
			titleOne,
			banner,
			recruitBox,
			recruitMboile
		},
		computed: {
			bannerS() {
				return this.$store.state.bannerS
			},
			lang() {
				return this.$store.state.lang
			},
		},
		data:function() {
			return {
				mobile:false
			};
		},
		created() {
			var width = document.body.clientWidth
			if(width <= 768){
				this.mobile = true
			}
		},
		mounted() {
			this.$nextTick(function(){
				if(this.$route.query.to != undefined || this.$route.query.to != null){
					setTimeout(()=>{
						document.getElementById(this.$route.query.to).scrollIntoView()
					},500)
				}else{
					document.body.scrollIntoView()
				}
			})
		},
		methods:{
		}
	}
</script>

<style lang="scss">
	$paddingTop:60px;
	#recruit{
		.recruitment{
			padding-top:$paddingTop;
		}
	}
</style>
