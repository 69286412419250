<template>
	<div id="recruitMobile">
		<ul>
			<li class="item" :class="item.active?'active':''" v-for="(item,index) in resume">
				<div @click="item.active = !item.active" class="left acea-row row-between-wrapper">
					<h4>{{item.position}}</h4>
					<span v-if="!item.active">{{lang.public.text24}}<i class="el-icon-arrow-down"></i></span>
					<span v-else>{{lang.public.text25}}<i class="el-icon-arrow-up"></i></span>
				</div>
				<div class="right">
					<h4 v-for="it in item.treatment">
						{{it}}
					</h4>
					<h4>{{lang.public.text21}}：</h4>
					<div class="info" v-html="item.duty"></div>
					<h4>{{lang.public.text22}}：</h4>
					<div class="cont" v-html="item.requirement"></div>
					
					<div @click="openBox" class="subBtn" :data-email="item.email">
						{{lang.public.text20}}
						<img class="icon" src="@/assets/icon_04.png" alt="">
					</div>
				</div>
			</li>
		</ul>
		<el-pagination v-if="total>10" :page-size="pageSize" :current-page="page" @current-change="joinChange" layout="prev, pager, next" :total="total"></el-pagination>
	</div>
</template>

<script>
	export default {
		data:function() {
			return {
				page:1,
				pageSize:10,
				resume:[],
				total:0,
			};
		},
		computed: {
			lang() {
				return this.$store.state.lang
			},
		},
		created() {
			this.getJoin()
		},
		mounted() {
		},
		methods:{
			getJoin(){
				this.$api.post('home/index/enterpriseRecruitment',{
					page:this.page,
					pageSize:this.pageSize
				}).then(res=>{
					var list = res.data.enterprise_recruitment
					list.map(item=>{
						item['active']=false
					})
					this.resume = list
					this.total = res.data.total
				})
			},
			joinChange(e){
				this.page = e
				this.getJoin()
			},
			openBox(e){
				var lang = this.lang.public
				var email = e.currentTarget.dataset.email
				this.$alert(lang.text23+email,lang.text20, {
					confirmButtonText:lang.text10,
					customClass:'joinMessage',
				});
			}
		}
	}
</script>

<style lang="scss">
	$paddingTop:60px;
	.joinMessage{
		max-width:100%;
		position: absolute;
		top:50%;
		left:50%;
		transform: translate(-50%,-50%);
		.el-message-box__header{
			span{
				font-size:24px;
			}
		}
		.el-message-box__message{
			p{
				font-size:18px;
			}
		}
	}
	#recruitMobile{
		margin-top:20px;
		.el-pagination{
			margin:50px auto 0;
			text-align: center;
			button{
				line-height:32px;
				height:32px;
				padding:0 8px;
				font-weight: 500;
				background-color: rgba($color: #1D2A55, $alpha:1);
				color: #FFF;
				i{
					font-size:16px;
				}
				&:disabled {
				    color: #1D2A55;
				    background-color: rgba($color: #1D2A55, $alpha:0.1);
				    cursor: not-allowed;
				}
			}
			li{
				line-height:32px;
				height:32px;
				padding:0 8px;
				font-size:16px;
				margin:0 5px;
				font-weight: 500;
				color:rgba($color: #1D2A55, $alpha: 0.4);
				&.active{
					color:rgba($color: #1D2A55, $alpha: 1);
				}
			}
		}
	
		ul{
			.item{
				margin-top:10px;
				display: block;
				width:100%;
				box-sizing: border-box;
				background:#FAF9FC;
				color:#1D2A55;
				transition: 0.3s;
				padding:0 10px;
				&.active{
					color:#fff;
					background:url(../assets/bg_03.png) no-repeat;
					background-size:cover;
					.right{
						display: block;
						.info{
							border-color:#FFF;
						}
						p{
							color:#FFF !important;
							span{
								color:#FFF !important;
							}
						}
						.subBtn{
							background:none;
							border:1px solid #FFF;
						}
					}
				}
				.left{
					position: relative;
					height:64px;
					h4{
						font-size:16px;
					}
					span{
						font-size:16px;
						i{
							margin-left:5px;
						}
					}
				}
				.right{
					display: none;
					padding-bottom:30px;
					h4{
						font-size:14px;
						font-weight: 500;
						line-height:28px;
					}
					.info{
					}
					p{
						font-size:14px !important;
						line-height:28px !important;
						color:#1D2A55 !important;
						span{
							font-size:14px !important;
							line-height:28px !important;
							color:#1D2A55 !important;
						}
					}
					.cont{
					}
					.subBtn{
						display:inline-block;
						width:120px;
						height:36px;
						background:#1D2A55;
						color:#FFF;
						text-align: center;
						line-height:36px;
						font-size:14px;
						margin-top:20px;
						position: relative;
						cursor: pointer;
						.icon{
							position: absolute;
							top:50%;
							right:-54px;
							width:77px;
							transform: translate(0,-50%);
						}
					}
				}
			}
		}
	}
</style>
